$(document).ready(function () {



    // $(window).load(function(){
    //     var url = window.location.href;
    //     $('.menu__ul li').find('.active').removeClass('active');
    //     $('.menu__ul li a').filter(function(){
    //         return this.href == url;
    //     }).parent().addClass('active').closest('.menu__dropdown').addClass('active');
    // });


    document.body.addEventListener('mousemove', function () {
        document.body.classList.remove('using-tab');
    });


    document.body.addEventListener('keydown', function (event) {
        if (event.keyCode === 9) {
            document.body.classList.add('using-tab');
        }
    });


    $('.text ,p ,h1 ,h2 ,h3 ,h4 ,h5 ').each(function () {
        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });

    function openSearch(e) {
        e.preventDefault();
        $('#search').fadeIn();
        setTimeout(function () {
            $('.search__input').focus();
        }, 200);
    }

    $('#showSearch').on('click', function (e) {
        openSearch(e);

    });

    $('#skipLinksSearch').on('click', function (e) {
        openSearch(e);
    })

    $('#closeSearch').on('click', function (e) {
        e.preventDefault();
        $('#search').fadeOut();
        setTimeout(function(){
            $('.search__input').val("");
        },500)
    });


    $(window).on('scroll', function () {
        if ($(window).width() < 993) {
            if ($(window).scrollTop() > 40) {
                $('.menuButton').addClass('fixed');
            } else {
                $('.menuButton').removeClass('fixed');
            }

        }

        if ($(window).scrollTop() > 200) {
            $('.scrollTop').addClass('show');
        } else {
            $('.scrollTop').removeClass('show');
        }


    })





    function closeMenu() {
        $('.menu__subList.open').removeClass('open').slideUp();
        $('.menu__dropdown').removeClass('open');

    }


    $('.menuButton').on('click', function () {
        setTimeout(function(){
            closeMenu();
        },500)
        $('html').toggleClass('overflow')
        $(this).toggleClass('active');
        $('.menu').fadeToggle();
    })


   $('.menu__dropdown > a').on('click', function(e){
       e.preventDefault();
       if ($(window).width() < 993) {
           if (!$(this).parent().hasClass('open')) {
               closeMenu();
               $(this).parent().addClass('open').find('.menu__subList').slideDown().addClass('open');
           } else {
               $(this).parent().removeClass('open').find('.menu__subList').slideUp().removeClass('open');
           }

       } else{
           $(this).parent().addClass('open');
           $(this).parent().find('.menu__subList').addClass('open');

           $('.menu__dropdown > .menu__subList > li:last-child').focusout(function(){
               $(this).parent().removeClass('open');
               $(this).closest('.menu__dropdown').removeClass('open');
           });

           $('.menu__dropdown').mouseleave(function(){
               $(this).find('ul.open').removeClass('open');
               $(this).removeClass('open');
           })
       }
   });




    if ($("a[rel^=lightbox]").length > 0) {

        var lightboxGallery = $("a[rel^=lightbox]").simpleLightbox({
            captionSelector: 'self'
        });
    }


});
